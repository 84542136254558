import ScreenForMobiles from '@Components/ScreenForMoviles';
import { useEffect, useState } from 'react';

type ResponsiveHandlerProps = { children: React.ReactNode };

const ResponsiveHandler = ({ children }: ResponsiveHandlerProps) => {
	const [isMobile, setIsMobile] = useState(false);

	// const handleResize = () => {
	// 	if (window.innerWidth < 1024) {
	// 		setIsMobile(true);
	// 	} else {
	// 		setIsMobile(false);
	// 	}
	// };

	// useEffect(() => {
	// 	handleResize();
	// 	window.addEventListener('resize', handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	return (
		<>
			<ScreenForMobiles show={isMobile} />
			{children}
		</>
	);
};

export default ResponsiveHandler;
